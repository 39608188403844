<template>
  <div
    v-if="$store.state.auth.role === 1"
  >
    <b-card no-body>
      <!-- Table Container Card -->
      <div
        class="mb-0"
      >
        <!-- Table Top -->
        <b-row
          class="m-2 d-flex justify-content-end"
        >

          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-center"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Utilisateurs</label>
          </b-col> -->

          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-form class="w-100">
                <b-input-group>
                  <b-form-input
                    placeholder="Recherche par mots-clés"
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      type="submit"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </div>
          </b-col> -->
          <b-col
            cols="12"
            md="3"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-link
                :to="{ name: 'device-types-create' }"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                >
                  Ajouter un type
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>

        <b-table
          v-if="devicetypesList.length > 0"
          class="position-relative"
          :items="devicetypesList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Aucun type trouvé"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(name)="data">
            <b-link
              :to="{ name: 'device-types-view', params: { id: data.item.id } }"
            >
              {{ data.item.name }}
            </b-link>
          </template>
          <template #cell(comment)="data">
            <small>
              {{ data.item.comment }}
            </small>
          </template>
        </b-table>
        <div
          v-else
          class="text-center"
        >
          <b-spinner
            v-if="isLoading"
            class="mt-5 mb-5"
          />
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{ dataMeta.from }} à {{ dataMeta.to }} sur {{ dataMeta.of }} type de devices</span>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable,
  BLink, BSpinner, VBTooltip, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BSpinner,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      searchField: null,
      devicetypesList: [],
      totalDevicetypes: null,
      tableColumns: [
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'comment', label: 'Commentaire' },
      ],
      perPage: 10,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      searchQuery: null,
      isSortDirDesc: true,
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.devicetypesList.length ? this.devicetypesList.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalDevicetypes,
      }
    },
  },
  watch: {
    perPage(newData) {
      this.perPage = newData
      this.currentPage = 1
      this.getDevicetypes()
    },
    currentPage(newData) {
      this.currentPage = newData
      this.getDevicetypes()
    },
    isSortDirDesc(newData) {
      this.currentPage = 1
      this.isSortDirDesc = newData
      this.getDevicetypes()
    },
    sortBy(newData) {
      this.currentPage = 1
      this.sortBy = newData
      this.getDevicetypes()
    },
    searchQuery(newData) {
      this.currentPage = 1
      this.searchQuery = newData
      this.getDevicetypes()
    },
  },
  created() {
    this.getDevicetypes()
  },
  methods: {
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'danger'
      return 'dark'
    },
    getDevicetypes() {
      this.isLoading = true
      // const buildLimit = `?limit=${(this.currentPage - 1) * this.perPage},${this.perPage}&sort=${this.sortBy},${this.isSortDirDesc ? '-1' : '1'}`
      store.dispatch('devicetype/findAllDevicetypes')
        .then(response => {
          this.devicetypesList = response
          this.totalDevicetypes = response.length
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.isLoading = false
          this.devicetypesList = []
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
